:root {
  --background-primary-color: #002B5B;
  --background-secondary-color: #2B4865;
  --primary-color: #256D85;
  --secondary-color: #8FE3CF;
  --primary-border: 1px solid var(--background-secondary-color);
  --primary-green: #02b64a;
  --primary-green-rgb: 2, 182, 74;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}