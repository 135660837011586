.privacy-terms-screen {
  flex: 1;
  padding: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > section > form {
    > div:first-child {
      display: flex;
      margin-bottom: 12px;
      > div {
        flex: 1;
      }
    }
    h5 {
      margin: 0;
    }
  }
  .policy-terms-container {
    overflow: auto;
    padding: 12px;
    > div > div {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
      > p > span:first-child:not(:only-child) {
        // margin-inline-end: 12px;
        // font-weight: bold;
      }
      p:first-child {
        > span:first-child {
          font-size: 20px;
          font-weight: normal;
        }
      }
      p:first-child + p {
        > span:first-child {
          font-size: 18px;
          font-weight: normal;
        }
      }
    }
  }
}
