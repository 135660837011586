.navbar-main {
  > ul {
    margin: 0;
    list-style: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    > a {
      display: flex;
      border: 1px solid var(--primary-green);
      height: 70px;
      width: 120px;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: var(--primary-color);
      > li {
        display: flex;
        height: 64px;
        width: 114px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid var(--primary-green);
      }
      &.active,
      &:hover {
        color: var(--secondary-color);
        background: rgba($color: var(--primary-green-rgb), $alpha: 0.3);
        font-weight: 500;
      }
    }
  }
}
