.agencies-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 6px 0;
  > .agencies-search {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  > .agencies-refresh {
    height: 100%;
    > button {
      height: 100%;
    }
  }
}