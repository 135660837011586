header {
  display: flex;
  align-items: center;
  justify-content: center;
  > h1 {
    flex: 1;
    text-align: center;
  }
  > button {
    margin-inline-start: auto;
  }
}
