.modal-base-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.modal-base-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-primary-color);
  color: var(--secondary-color);
  padding: 20px;
  z-index: 1000;
  width: 560px;
  > .modal-base-close-btn {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    > button {
      cursor: pointer;
    }
  }
  h3,
  h4 {
    margin: 0;
  }
  .modal-base-header {
    margin-bottom: 12px;
  }
  .modal-form-inputs-container {
    margin-bottom: 12px;
    .modal-form-input-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      &.row {
        flex-direction: row;
        gap: 6px;
      }
      > label {
        margin-bottom: 4px;
      }
      > textarea {
        resize: vertical;
        max-height: 140px;
      }
    }
  }
  .modal-form-actions-container {
    display: flex;
    gap: 6px;
  }
}
