.agencies-screen {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    > .agency-controls {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 6px 0;
      > .agency-search {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      > .agency-refresh {
        height: 100%;
        > button {
          height: 100%;
        }
      }
    }
    > .agencies-table-wrapper {
      flex: 1 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      > .agencies-table-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      }
    }
  }