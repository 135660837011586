.login-screen-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  > form {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
