.companies-notifcations-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > .companies-notifcations-table-wrapper {
    flex: 1 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    > .companies-notifcations-table-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
