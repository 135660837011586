.notifications-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > .notifications-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 6px 0;
    > .notifications-controls-btn-container {
      display: flex;
      justify-content: center;
      flex: 1;
    }    
  }
  > .users-table-wrapper {
    flex: 1 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    > .users-table-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
