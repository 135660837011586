.deleted-agencies-table-container {
  &:last-child {
    border-bottom: 1px solid var(--primary-green);
  }
  > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    & th {
      border: 1px solid var(--primary-green);
      border-inline-start: none;
      text-align: start;
      background: rgba(var(--primary-green-rgb), 0.3);
      &:first-child {
        border-inline-start: 1px solid var(--primary-green);
      }
    }
    & > thead {
      position: sticky;
      top: 0;
      background-color: var(--background-primary-color);
    }
    & > tbody {
      > tr {
        height: 46px;
        &:nth-child(2n + 1) {
          background-color: var(--background-secondary-color);
        }
      }
    }
    & th,
    & td {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.deleted-agencies-table-paging {
  color: red;
  background-color: yellow;
  margin-top: auto;
}
