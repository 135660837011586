.downloads-management-screen {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px 6px;
  > .downloads-management-controls {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 12px;
    > .downloads-management-open-all {
      display: flex;
      align-items: center;
      justify-content: center;
      > button {
        width: 100%;
      }
    }
    > .downloads-management-user-search-container {
      display: flex;
      align-items: center;
      gap: 10px;
      > .downloads-management-user-search {
        flex: 1;
      }
    }
  }
  > .downloads-management-navbar-wrapper {
    > nav {
      > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        > li {
          cursor: pointer;
          flex: 1;
          text-align: center;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          padding: 6px;
          &.active-tab {
            background-color: var(--background-secondary-color);
          }
          &:hover:not(.active-tab) {
            opacity: 0.7;
          }
        }
      }
    }
  }
  > .downloads-management-tables-wrapper {
    background-color: var(--background-secondary-color);
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    > .reports-prefs-table-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
